/* eslint-disable no-useless-escape */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect } from 'react';

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

const BlogPost = ({ data }) => {
  const { frontmatter, excerpt, html, timeToRead, headings } =
    data.markdownRemark;

  const blogHeadings = headings || [];

  const options = {
    threshold: 0,
    rootMargin: '-10% 0px',
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute('id');

        if (entry.isIntersecting) {
          document
            .querySelector(`ul li a[href="#${id}"]`)
            .parentElement.classList.add('active');
        } else {
          document
            .querySelector(`ul li a[href="#${id}"]`)
            .parentElement.classList.remove('active');
        }
      });
    }, options);

    document.querySelectorAll('h2[id]').forEach((heading) => {
      observer.observe(heading);
    });

    return () => observer.disconnect();
  });

  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description || excerpt}
    >
      <div sx={styles.container}>
        <h1 sx={styles.title}>{frontmatter.title}</h1>
        <div sx={styles.metaInfo}>
          <span>{frontmatter.date}</span>
          {' | '}
          <span>{timeToRead} mins read</span>
        </div>
        <div sx={{ px: [0, 4] }}>
          <GatsbyImage
            image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
            alt=""
            sx={styles.coverImage}
          />
        </div>

        <div sx={styles.contentWrapper}>
          <section>
            <div sx={styles.tableOfContentsWrapper}>
              <h2 sx={styles.tableOfContentsHeading}>Contents</h2>
              <ul sx={styles.tableOfContents}>
                {blogHeadings.map((heading) => {
                  return (
                    <li key={heading.value} sx={styles.tableOfContentsItem}>
                      <a href={`#${slugify(heading.value)}`}>{heading.value}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <article
            sx={styles.article}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

const styles = {
  container: {
    py: 5,
    pb: 6,
    mt: [5, 0],
  },

  title: {
    color: 'purplegray',
    textAlign: 'center',
    mb: 1,
    textTransform: 'capitalize',
    fontSize: [4, 5, "36px"],
    maxWidth: '800px',
    mx: 'auto',
  },

  metaInfo: {
    textAlign: 'center',
    textTransform: 'lowercase',
    opacity: 0.5,
    mb: 4,
    fontSize: [0],

    span: {
      display: 'inline-block',
    },
  },

  coverImage: {
    borderRadius: [0, '8px'],
    mb: [-1, -1, -6],
    zIndex: -1,
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    borderRadius: '8px',
    justifyContent: 'space-around',
    bg: 'white',
    maxWidth: '78rem',
    p: [2, 4],
    py: [3, 5],
    mx: 'auto',
  },

  tableOfContentsWrapper: {
    position: 'sticky',
    top: '5%',
    flex: '1 1 15rem',
    maxWidth: '15rem',
    mr: 4,
    mb: 4,
  },

  tableOfContentsHeading: {
    fontSize: [0],
    fontWeight: 600,
    textTransform: 'capitalize',
    opacity: 0.4,
  },

  tableOfContents: {
    mt: 3,
  },

  tableOfContentsItem: {
    p: 2,
    paddingLeft: '1.5rem',
    borderLeft: '2px solid',
    borderColor: 'rgba(229, 231, 235, 1)',

    a: {
      color: 'inherit',
      fontWeight: 600,
      fontSize: '0.9rem',
      opacity: 0.4,
      transition: 'all .25s linear',
    },

    'a:hover': {
      color: '#35005b',
      transition: 'all .25s linear',
      opacity: 1,
    },

    '&.active a': {
      opacity: 1,
    },

    '&.active': {
      color: '#777DF2',
      borderColor: '#777DF2',
      opacity: 1,
    },
  },

  article: {
    flex: '0 1 70ch',
    maxWidth: '50rem',
    wordWrap: 'break-word',
    color: 'rgb(55, 41, 44)',

    p: {
      fontSize: '1.1rem',
      lineHeight: 1.5,
      mt: 4,
    },

    'h1 + p, h2 + p, h3 + p': {
      mt: 1,
    },

    ol: {
      p: 0,
      mt: 4,
      pl: '2rem',
    },

    ul: {
      pl: '2rem',
      mt: '12px',
    },

    li: {
      listStyleType: 'square',
    },

    '& > * + *': {
      mt: 2,
    },

    '& li + li': {
      mt: 2,
    },

    '& > div + p': {
      mt: 5,
    },

    '& > p + div': {
      mt: 5,
    },

    '* + h1, * + h2, * + h3': {
      mt: [5, 6, '24px'],
    },

    'h1, h2, h3': {
      color: 'inherit',
    },

    h1: {
      fontSize: [3, 4, 5],
    },

    h2: {
      fontSize: [4, 5],
    },

    'h2 a': {
      color: 'purple',
    },

    h3: {
      fontSize: [3, 4],
    },

    'b, strong': {
      fontWeight: 'bold',
    },

    a: {
      color: 'purple',
    },

    'a:hover': {
      textDecoration: 'underline',
    },

    blockquote: {
      fontStyle: 'italic',
      borderLeft: '5px solid',
      borderColor: 'purplegray',
      pl: 3,
    },

    pre: {
      overflowX: 'auto',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
    },

    code: {
      overflowX: 'auto',
      whiteSpace: 'pre-wrap',
      fontFamily: 'monospace',
    },
  },
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      html
      timeToRead
      headings(depth: h2) {
        value
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        coverImage {
          childImageSharp {
            gatsbyImageData(height: 620, width: 1500, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
